// Here you can add other styles

/************** <HEADER> **************/

// Контейнер для шапки
.rdev__header {
	height: $header-height;
	// запрещаем перенос
	flex-wrap: nowrap;
	// Контейнер для части шапки
	// ( левая и правая части )
	&_part {
		display: flex;
	}
	// для левой части разрешаем перенос
	&_part:first-child {
		flex-wrap: wrap;
	}
}

// контейнер для лого платформы и ссылки на портал/админку
.rdev__header__nav {
	display: flex;
	align-items: center;
	justify-content: center;
}

// Логотип платформы
.rdev__logo {
	// по умолчанию будет первым
	order: -1;
	// задаем фоновую картинку
	background: url(scss/nbd/img/logonbd.svg) 50% 50% no-repeat;
	background-size: 150.2px;
	// ну и размеры укажем
	height: 50px;
	width: 160px;
}

// Контейнер меню для профиля
.navbar-nav.navbar__profile {
	display: flex;
}

/************** </HEADER> **************/

.rdev__header__navlnk {
	color: $navbar-color;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 25px;
}

.rdev__header__navlnk:hover {
	color: $navbar-hover-color;
	text-decoration: none;
}

.rdev__header__navlnk:active {
	color: $navbar-active-color;
}

/************** </HEADER> **************/

/************** <Main> **************/

.rdev-banner-image {
	background-size: contain !important;
}

// высота менюшек ( 50px - высота хедаера) 
.sidebar {
	height: calc(100vh - 50px) !important;
}

// карточка
.card {
	margin: 0;
}

// шапка карточки
.card-header {
	padding: 5px 7px 5px 10px;
}

// Отступ между кнопками тулбара
.btn-toolbar > span:not(:last-child) {
	margin-right: 7px;
}

// отступ под тулбаром
.btn-toolbar {
	margin: 5px 0;
}

// внутренние отступы ячеек таблицы ( кроме пустых и фильтров )
.dx-datagrid .dx-row:not(.dx-freespace-row) > td:not(.dx-editor-cell) {
	padding: 5px !important;
}

// Внутренние отступы и высота фильтров
.dx-datagrid-filter-row td .dx-editor-container .dx-filter-range-content,
.dx-editor-cell > div > div > div > div > div .dx-texteditor-input {
	padding: 1px 3px 0 25px !important; 
	min-height: 22px !important;
}

.extended-filter-icon-parent>div>div>div>div>div .dx-texteditor-input {
	padding: 1px 25px 0 25px !important;
	min-height: 22px !important;
}

// внутренние отступы для фильтров без менюшки выбора типа поиска
.dx-editor-cell > div:not(.dx-editor-with-menu)>.dx-editor-container>div>div>div .dx-texteditor-input {
	padding: 0 0 0 5px !important;
}

// плэйсхолдер выпадающих списков
.dx-selectbox-container > div > .dx-texteditor-input-container > .dx-placeholder:before {
	padding: 0 5px;
}

// иконка календаря фильтров
.dx-datebox-calendar .dx-dropdowneditor-icon:before {
	margin-top: -8px !important;
}

// отступы и высота полей ввода для типа фильтрации в диапазоне
.dx-datagrid-filter-range-overlay>div>.dx-editor-container>div>div>.dx-texteditor-input-container>.dx-texteditor-input,
.dx-datagrid-filter-range-overlay>div>.dx-editor-container>div>div>.dx-texteditor-input-container>.dx-placeholder::before,
.dx-datagrid-filter-range-overlay>div>.dx-editor-container>div>.dx-dropdowneditor-input-wrapper>div>.dx-texteditor-input-container>.dx-placeholder::before,
.dx-datagrid-filter-range-overlay>div>.dx-editor-container>div>.dx-dropdowneditor-input-wrapper>div>.dx-texteditor-input-container>.dx-texteditor-input {
	min-height: 22px !important;
	padding: 2px 5px;
}

// высота и внутренние отступы меню поиска
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
	height: 22px !important;
	width: 25px;
}

// сама иконка поиска
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
	height: 20px;
	margin: 0 3px !important;
}

// отступ шапки таблицы
.dx-datagrid-headers .dx-datagrid-content {
	margin-bottom: -1px;
}

// отступы профиля пользователя
.rdev_profile,
.rdev_container {
	margin: 0 5px;
}

// высота диаграммы базы данных
.storm-diagrams-canvas {
	height: 90% !important;
}

/************** </Main> **************/

/************** < LOGIN PAGE > **************/

.rdev_login {

	min-width: 315px;

	&__card {
		max-width: 445px;
		margin: 0 auto;
	}

}
.app-body {
	margin-top: $header-height !important;
}
/************** </ LOGIN PAGE> **************/

/************** <Media-запросы> **************/
// при разрешении экрана менее 990 пикселей
@media (max-width: 992px) {

	.rdev__header {
		height: $header-column-height;
	}

	.app-body {
		margin-top: $header-column-height !important;
	}

	// логотип переносим в следующую колонку
	.rdev__logo {
		order: 0;
	}

	// профиль выстраиваем в колонку с инверсией
	.navbar-nav.navbar__profile {
		flex-direction: column-reverse;
	}

	.sidebar {
		height: calc(100vh - 120px) !important;
	}
}

// при разрешении экрана менее 390 пикселей
@media (max-width: 390px) {
	// логотип подгрузим другой
	.rdev__logo {
		background: url(scss/nbd/img/logonbd_mini.svg) 50% 50% no-repeat;
		// умещаем фоновую картинку
		background-size: contain;
		// изменим размеры и отступы
		width: 30px;
		margin-left: 10px;
		margin-right: 10px;
	}

	// зададим ширину контейнеру с лого и ссылкой
	.rdev__header__nav {
		width: 8rem;
	}

	// уменьшем внутренние отступы
	.rdev-banner-col {
		padding: 25px !important;
	}
	// уменьшим шрифт заголовка
	.rdev-banner-title {
		font-size: 20px !important;
	}

	// Страница Логина
	.rdev_login {

		&__cardbody {
			padding: 0;
		}
	}

}
/************** </Media-запросы> **************/


// https://rm.mfc.ru/issues/67317


.main .container-fluid {
	padding: 0 0;
	height: 100%;
}

.card-body {
	padding: 7px;
}

.app-footer {
	flex-basis: $footer-height;
}

// Решение конфликтов стилей в react окнах
.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
	color: #fff;
	background-color: #264859;
	border-color: #234150;
}

.btn .c-icon, .btn i {
	height: .875rem;
	margin: 0px
}

// Добавляем css для тулбара в рамках задачи https://rm.mfc.ru/issues/43116
/// Сохранить
.btn-save-toolbar {
	color: #ffffff;
	background-color: #07947e;
	border-color: #5bb8aa;
}

.btn-save-toolbar:hover {
	color: #ffffff;
	background-color: #057160;
	border-color: #096557;
}

.btn-save-toolbar.disabled, .btn-save-toolbar:disabled, .btn-save-toolbar[disabled] {
	color: #ffffff;
	background-color: #cfeae6;
	border-color: #d5ece9;
}
/// Удалить
.btn-delete-toolbar {
	color: #ffffff;
	background-color: #cc3333;
	border-color: #dd7878;
}

.btn-delete-toolbar:hover {
	color: #ffffff;
	background-color: #ad2b2b;
	border-color: #a32929;
}

.btn-delete-toolbar.disabled, .btn-delete-toolbar:disabled, .btn-delete-toolbar[disabled] {
	color: #ffffff;
	background-color: #eed3d3;
	border-color: #f6e8e8;
}
/// Зактрыть
.btn-close-toolbar {
	color: #ffffff;
	background-color: #325e74;
	border-color: #d1dbe0;
}

.btn-close-toolbar:hover {
	color: #ffffff;
	background-color: #264859;
	border-color: #234150;
}

.btn-close-toolbar.disabled, .btn-close-toolbar:disabled, .btn-close-toolbar[disabled] {
	color: #ffffff;
	background-color: #c3d8dd;
	border-color: #f4f8f8;
}
/// Общий размер кнопок
.btn-toolbar-size {
	padding: 0.5rem 1rem;
	font-size: 0.89rem;
	line-height: 1;
	border-radius: 0.3rem;
}


/// Доработка меню по задаче rm.mfc.ru/issues/41420

// Цвет вложенных элементов делаем светлым
.nav-dropdown-items {
	background: $nested-items-light;
}

.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
	background: $nested-items-light;
}

@media (min-width: 992px) {
	.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
		background: $nested-items-light;
	}
}

.nav-item .nav-dropdown {
	background: $nested-items-light;
}

.sidebar .nav-item.nav-dropdown.open {
	background: $nested-items-light;
}
// Темный цвет выделенного пункта меню
.sidebar .nav-link.active {
	background: $selected-item-dark;
}

// Цвет главных элементов под цвет всего меню
.sidebar ul.nav > li.nav-item.nav-dropdown.open {
	background: $main-menu-color;
}

.sidebar ul.nav > li.open {
	background: $main-menu-color;
}

// Отступы для уровней вложенности
// Для текущей ширины меню(230px) доступно всего 5 уровней подменю с шагом $indent-step-menu начиная с начальной позиции 16px
// При наличии большего количества подуровней отступ будет фиксированным на уровне в (16 + 5 * $indent-step-menu)px
.sidebar .nav-dropdown-items li > a {
	padding-left: calc( 16px + #{$indent-step-menu} );
}

.sidebar .nav-dropdown-items li > ul > li > a {
	padding-left: calc( 16px + 2 * #{$indent-step-menu} )
}

.sidebar .nav-dropdown-items li > ul > li > ul > li > a {
	padding-left: calc( 16px + 3 * #{$indent-step-menu});
}

.sidebar .nav-dropdown-items li > ul > li > ul > li > ul > li > a {
	padding-left: calc( 16px + 4 * #{$indent-step-menu});
}

.sidebar .nav-dropdown-items li > ul > li > ul > li > ul > li > ul > li > a {
	padding-left: calc( 16px + 5 * #{$indent-step-menu});
}
/// Доработка меню по задаче rm.mfc.ru/issues/41420


/// добавлено для Select в динамические формы
// https://rm.mfc.ru/issues/53873
.choices__list--dropdown > div :hover {
	white-space: normal;
}

div.form-control.ui.fluid.selection.dropdown > div > div > span :hover ::after {
	white-space: normal;
	color: red;
}

@media (min-width:640px) {
	.choices__list--dropdown .choices__item--selectable2 {
		padding-right: 100px
	}

	.choices__list--dropdown .choices__item--selectable2:after {
		content: attr(data-select-text);
		font-size: 12px;
		opacity: 0;
		position: absolute;
		right: 10px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	[dir=rtl] .choices__list--dropdown .choices__item--selectable2 {
		text-align: right;
		padding-left: 100px;
		padding-right: 10px
	}

	[dir=rtl] .choices__list--dropdown .choices__item--selectable2:after {
		right: auto;
		left: 10px
	}
}

.choices__list--dropdown .choices__item--selectable2.is-highlighted {
	background-color: #f2f2f2
}

.choices__list--dropdown .choices__item--selectable2.is-highlighted:after {
	opacity: .5
}

.choices__item {
	cursor: default
}

.choices__item--selectable2 {
	cursor: pointer
}

.choices__list--dropdown .choices__item--selectable2 {
	padding-right: 0
}

// Выбранный элемент обрезаем в одну строку с троеточием
.choices__item.choices__item--selectable2 {
	white-space: nowrap;
	overflow: hidden;
	padding-right: 25px;
	text-overflow: ellipsis
}
// Элементы списка выводим без обрезания текста
div.choices__item.choices__item--choice.choices__item--selectable2 {
	white-space: normal;
}

// Позволяем видеть весь текст при наведении на выбранный элемент
// https://rm.mfc.ru/issues/57096
// поправил селектор
.choices__list.choices__list--single :hover {
	white-space: normal;
	overflow: hidden;
	text-overflow: clip;
	background-color: white;
	z-index: 1;
	padding: 0px 0px 0px 0px;
	width: calc(100% - 30px);
}

// Защищаем от перерисовки вложенные элементы
div.choices.form-group.formio-choices > div.form-control.ui.fluid.selection.dropdown > div.choices__list.choices__list--single > div :hover {
	padding: 0;
	border: none;
}

// Доработка https://rm.mfc.ru/issues/54673
.choices__list--multiple .choices__item {
	// Меняем с inline-block для разрешения переноса
	display: block !important;
	// Другой вариант решения задачи
	//white-space: normal;
}

// https://rm.mfc.ru/issues/57098
.form-control.is-invalid {
	// немного передвигаем алеррт влево
	background-position: right calc(0.375em + 1.3rem) center;
}

// https://rm.mfc.ru/issues/56846?issue_count=12&issue_position=3&next_issue_id=56678&prev_issue_id=49405
// центруем кнопки
// .rdev-login-buttons {
// 	justify-content: center;
// }

// https://rm.mfc.ru/issues/44066
// https://rm.mfc.ru/issues/56968
// ограничиваем тело модалки
.crypt_body {
	height: 65vh;
}

.crypt_list {
	padding: 0;
	height: 100%;
	list-style: none;
	overflow: overlay;

	&_item {
		display: flex;
		min-height: 60px;
		font-size: 18px;
		cursor: pointer;
		background-color: #325e74;
		color: #fff;
		margin: 2px;
	}

	&_item:hover {
		background-color: #3d7c8f;
	}
}

.crypt_item_part {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 50%;
	padding: 5px 10px;
}

.crypt_item_part:first-child {
	border-right: 1px solid #7A9BBC;
}

.crypt_item_part > span {
	width: -webkit-fill-available;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.crypt_item_part:hover > span {
	color: #A7BDD3;
	overflow: visible;
	overflow-wrap: break-word;
}

.crypt_item_part:last-child {
	font-size: 15px;
}

.crypt_email {
	color: #A7BDD3;
	font-size: 16px;
}

// https://rm.mfc.ru/issues/56878
// https://rm.mfc.ru/issues/56921
.dx-data-row {
	height: 30px;
}

/*
***************************************
https://rm.mfc.ru/issues/57992

*****************************************
*/
// переопределяем стили

// блок-списка фильтров
.dx-dropdownlist-popup-wrapper > .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
	// устанавливаем минимальную ширину (ширина колонки)
	min-width: 100%;
	// устанавливаем ширину элемента по максимальной ширине контента
	width: max-content !important;
	// ограничиваем максимальную ширину
	max-width: 50vw !important;
	// разрешаем перенос
	white-space: normal !important;
	// показываем переполнение
	overflow: visible;
}

// контетнт эелемента списка
.dx-list-item-content {
	// показываем переполнение
	overflow: visible !important;
	// перенос с любого места
	word-break: break-all;
	// разрешаем перенос
	white-space: normal !important;
}

// эелемент списка
.dx-item.dx-list-item {
	// минимальная ширина 100% (для растягивание на ширину колонки)
	min-width: 100%;
	// ширина выбирается автоматически
	width: auto !important;
}

// Элемент ошибок валидации фильтра таблицы
.validateFilterMessage {
	position: absolute;
	border: 2px solid red;
	background-color: white;
	text-align: center;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	padding: 5px;
	z-index: 9999;
}

.validateFilterMessage::before {
	content: "";
	border: solid transparent;
	position: absolute;
	left: 8px;
	bottom: 100%;
	border-bottom-color: #f00;
	border-width: 9px;
	margin-left: 0;
}

.dx-overlay-content.dx-resizable.dx-invalid-message-content {
	display: none;
}


/// Стили контролов элемента подписания
.sign-max-width {
	width: 100%;
	max-width: 100%;
}

.sign-pointer {
	cursor: pointer;
}

.sign-pointer:hover {
	cursor: pointer;
	background: #e8f3fa !important;
}

.sign-style-left-nowrap {
	text-align: left;
	width: 0;
	white-space: nowrap;
}

.sign-style-right-nowrap {
	text-align: right;
	width: 0;
	white-space: nowrap;
}

.middle-text > tr > td {
	vertical-align: middle;
}

.sign-name-short {
	text-overflow: ellipsis; /* Добавляем многоточие */
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

// Убираем подсвечивание активных пунктов меню, добавляя им класс пассивных-ссылок
li.passive-link.nav-item > a {
	background-color: $sidebar-bg !important;
}

li.passive-link.nav-item :hover {
	background-color: $sidebar-nav-link-hover-bg !important;
}

.rdev__plugin_card_body {
	overflow-x: auto;
}

.text_hide {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scroll_container {
	overflow-y: auto;
	max-height: 90vh;
}

.input_password {
	// border: 1px solid red;
	border-radius: 0.25rem 0 0 0.25rem;
}

.btn_password {
	border-radius: 0;
	background-color: #325e74;
	border: none;
	color: #fff;
	&--second {
		border-radius: 0.0 0.25rem 0.25rem 0;
	}
	&:not(:disabled):hover {
		background-color: #234150;
		color: #fff;

	}
	&:focus {
		box-shadow: none;
	}
	&:active {
		box-shadow: none;
	}
	&:active:focus {
		box-shadow: none !important;
	}
	
}

// https://rm.mfc.ru/issues/60161
.btn:focus {
	box-shadow: none;
}

// https://rm.mfc.ru/issues/66109
.rdev__notify__listitem {
	padding: 0.75rem 0.25rem;
	
	&-textblock {
		cursor: pointer;
		padding: 10px;
		transition: all 0.5s ease;
	}

	&-textblock:hover {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}

	&-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// перенос с любого места
		word-break: break-word;
	}

	&-text:hover {
		// разрешаем перенос
		white-space: normal;
		overflow: visible;
	}

	&-close:hover {
		transform: scale(1.2);
	}
}

.rdev__notify {
	&__column {

		&-message {
			min-height: 35px;
			background-color: #e4e7ea;
			height: fit-content;
			// разрешаем перенос
			word-break: break-word;
			overflow: visible;
		}

		@media (max-width: 1199px) {
			&-sm {
				flex: 0 0 16.66667%;
				max-width: 16.66667%;
			}
			&-md {
				flex: 0 0 83.33333%;
				max-width: 83.33333%;
			}
		}
		@media (max-width: 576px) {
			&-sm {
				flex: none;
				max-width: none;
			}
			&-md {
				flex: none;
				max-width: none;
			}
		}
	}
}

.rdev__notify__message {
	padding: 0;
	&-text {
		width: 280px;
		padding: 6px 8px;
	}
	button {
		padding: 8px 10px;
	}
}

.project__highlight {
	background: #ececec;
	font-weight: 600;
}

// https://rm.mfc.ru/issues/70439 Пользовательский CSS-класс для дин. формы
.custom-label {
	label {
		&::before {
			right: -104% !important;
		}
	}
}

// Кнопка перехода к связанной записи
.table__btn--link {
	margin-right: 0.7rem
}

// Кнопка открытия модалки с списком файлов
.table__btn--icon {
	padding: 0;
	margin-right: 0.7rem;
	border: none;
	background-color: unset;
}
.table__btn--icon,
.table__btn--icon:focus,
.table__btn--icon:active {
	outline: none;
}

.card {
	margin-bottom: 1rem;
}

.unborderer {
	border: none;
}

// Для кастомной кнопки Очистить поля SysRelation
// Для исключения разного отображения по сути одинаковых инпутов
.lighted {
	opacity: 0.5;
	background-color: transparent !important;
}

.lighted:hover {
	background-color: transparent !important;
}
