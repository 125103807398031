// Variable overrides
$sidebar-width: 230px;

// фикс для слишком длинного меню
.nav-dropdown.open {
	> .nav-dropdown-items {
		max-height: 5000px !important;
	}
}

$header-height: 50px;
$header-column-height: 120px;

// Тут можно задать высоту футера. 
// Так-как он на данный момент пустой, поставил 0
$footer-height: 0px;